import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {
  // This function display a common loader inside our app
  display(value: boolean) {
    value ? $('.jsMainLoader').show() : $('.jsMainLoader').hide();
  }

  // This function show a loader insider a particular element
  showInlineLoader(selector: string) {
    $(selector).append('<div class="jsInlineLoader blockInlineLoader"><svg class="icon loader"><use xlink:href="#loader"></use></svg></div>');
  }

  // This function hide a loader insider a particular element
  hideInlineLoader(selector: string) {
    $(selector).find('.jsInlineLoader').remove();
  }
}
