import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResponseAlert } from '../models/response-alert';

@Injectable()
export class AlertService implements OnDestroy {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;
  public unsubscribe: Subject<void> = new Subject<void>();
  constructor(private router: Router) {
    // clear alert message on route change
    router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  success(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'success', text: message });
  }

  successPage(message: string) {
    this.subject.next({ type: 'success', text: message });
  }

  error(errorObj: any, message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'danger', text: message });
  }

  warning(message: string) {
    this.subject.next({ type: 'warning', text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  filterCustomAlertsById(alerts: ResponseAlert[], status: number): ResponseAlert[] {
    return alerts.filter((er) => er.id === status);
  }
}
