import { Injectable } from '@angular/core';
import axios, { Axios, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { HttpGruberError } from './../../models/http-gruber-model';
import { HTTPMethod } from './../../models/enum';

const SUCCESS_STATUS = [200, 204, 201, 202];
@Injectable()
export class HttpGruberClient {
  private axiosClient: Axios = axios.create({ withCredentials: false, timeout: 5000, headers: { 'content-type': 'application/json' } });
  constructor() {}
  private async get<T>(url: string, headers: AxiosRequestHeaders, params: any): Promise<AxiosResponse<T>> {
    return await this.axiosClient.get<T>(url, { headers: headers, params: params });
  }
  private async post<T>(url: string, headers: AxiosRequestHeaders, params: any, payload): Promise<AxiosResponse<T>> {
    return await this.axiosClient.post<T>(url, payload, { headers: headers, params: params });
  }
  private async put<T>(url: string, headers: AxiosRequestHeaders, params: any, payload): Promise<AxiosResponse<T>> {
    return await this.axiosClient.put<T>(url, payload, { headers: headers, params: params });
  }
  private async delete<T>(url: string, headers: AxiosRequestHeaders, params: any): Promise<AxiosResponse<T>> {
    return await this.axiosClient.delete<T>(url, { headers: headers, params: params });
  }

  public async httpMethod<T>(
    method: HTTPMethod,
    apiUrl: string,
    queryStringParameters: Record<string, string>,
    headers: Record<string, string>,
    payload: any
  ): Promise<T> {
    try {
      let response: AxiosResponse<T>;
      switch (method as HTTPMethod) {
        case HTTPMethod.GET:
          response = await this.get<T>(apiUrl, headers, queryStringParameters);
          break;
        case HTTPMethod.POST:
          response = await this.post<T>(apiUrl, headers, queryStringParameters, payload);
          break;
        case HTTPMethod.PUT:
          response = await this.put<T>(apiUrl, headers, queryStringParameters, payload);
          break;
        case HTTPMethod.DELETE:
          response = await this.delete<T>(apiUrl, headers, queryStringParameters);
          break;
      }
      if (!response || !SUCCESS_STATUS.includes(response.status)) {
        const errorMessage = `Cannot complete the ${HTTPMethod[method]} request to the Url ${apiUrl}: ${response.statusText} (status ${response.status})`;
        console.error(errorMessage);
        throw new HttpGruberError(response.statusText, response.status);
      }
      return response.data;
    } catch (err: any) {
      const errorMessage = `Cannot complete the ${HTTPMethod[method]} request to the Url ${apiUrl}: ${err?.message})`;
      console.error(errorMessage);

      const errMsg = err instanceof HttpGruberError ? (err as HttpGruberError).message : err?.response?.data?.Message;
      const errStatus = err instanceof HttpGruberError ? (err as HttpGruberError).status : err?.response?.status;

      throw new HttpGruberError(errMsg, errStatus);
    }
  }
}
