import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertService } from './services';
import { getConfigService } from './services/get-config.service';
import { LoaderService } from './services/loader.service';
import { storageAdapter } from './services/session.service';
import { WINDOW_PROVIDERS } from './window.providers';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonFunctions } from './services/shared/common-functions';
import { BaseAdapter } from './services/base.service';
import { registerLocaleData } from '@angular/common';
import localeDeIt from '@angular/common/locales/it';
import { ConfirmationDialogService } from './services/confirmation-dialog.service';
import { GlobalErrorHandler } from './services/shared/global-error-handler';
import { HttpGruberClient } from './services/shared/http-gruber-client.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeDeIt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    getConfigService,
    AlertService,
    LoaderService,
    storageAdapter,
    WINDOW_PROVIDERS,
    CommonFunctions,
    BaseAdapter,
    ConfirmationDialogService,
    GlobalErrorHandler,
    HttpGruberClient,
    TranslateService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
