import { Injectable, Inject } from '@angular/core';
import { WINDOW } from './../window.providers';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class storageAdapter {
  public userDetailsChanged = new BehaviorSubject<any>(this.getJsonSession());

  constructor(@Inject(WINDOW) private window: Window, private translate: TranslateService) {}

  /**
   * This function used to return  the current language set in the site
   */
  public getLanguage(): string {
    let language: string = localStorage.getItem('UserLanguage');
    if (language) {
      return language.toLowerCase();
    } else {
      const browserLang = window.navigator.language.split('-')[0];
      if (browserLang.match(/en|it|de|pl|ro|ru|sk|hu|bg|cs|hr|mk|sr/)) {
        language = browserLang;
      } else {
        language = 'en';
      }
    }

    return language;
  }
  public getCurrentToken() {
    return localStorage.getItem('authToken') ?? '';
  }

  /**
   * This function is used to clean all the cookie around
   * used for example when we log out
   */
  public cleanAll(): void {
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('UserLanguage');
    localStorage.removeItem('CRM_Companies');
  }

  /**
   * Function used to set the language for the entire site
   * @param language
   */
  public setLanguage(language: string): void {
    localStorage.setItem('UserLanguage', language.toLowerCase());
    this.translate.use(language);
    // We need to set the language even in the object in the user (session)
    const userData: any = this.getJsonSession();
    if (userData.hasOwnProperty('user')) {
      userData.user.language = language.toLowerCase();
      this.setUser(userData);
    }
  }

  /**
   * Return the json rapresentation of our session
   */
  public getJsonSession(): any {
    if (localStorage.getItem('isLoggedin')) {
      return JSON.parse(localStorage.getItem('isLoggedin'));
    }

    return {};
  }

  // Set the user inside our session
  public setSession(data) {
    // We need to stringify the data
    const userString: string = JSON.stringify(data);
    localStorage.setItem('isLoggedin', userString);
  }

  // Set usetr data
  public setUser(data) {
    const userData: any = this.getJsonSession();
    userData.user = data.user;
    userData.user_status = data.user_status;
    this.setSession(userData);
    this.userDetailsChanged.next(userData);
  }
}
