import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { storageAdapter } from './services/session.service';
import { CommonFunctions } from './services/shared/common-functions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewChecked, OnInit, OnDestroy {
  public currentTitleOfPage: string;
  public unsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private commonFunctions: CommonFunctions,
    private translate: TranslateService,
    private storageAdapter: storageAdapter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        takeUntil(this.unsubscribe)
      )
      .subscribe((event) => {
        this.titleService.setTitle(this.translate.instant('TitleTab.' + event.title));
        this.currentTitleOfPage = event.title;
      });

    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.titleService.setTitle(this.translate.instant('TitleTab.' + this.currentTitleOfPage));
    });
  }
  title = 'gl-app-carrierRegistration';
  ngAfterViewChecked(): void {
    this.commonFunctions.applyCustomScrollbar();
  }
  ngOnInit(): void {
    this.translate.addLangs(['en', 'it', 'de', 'pl', 'ro', 'ru', 'sk', 'hu', 'bg', 'cs', 'hr', 'mk', 'sr']);
    this.translate.setDefaultLang('en');

    const currentLanguage: string = this.storageAdapter.getLanguage();
    this.translate.use(currentLanguage);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
