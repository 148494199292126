import { Injectable, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import OverlayScrollbars from 'overlayscrollbars';
import { storageAdapter } from '../session.service';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CommonFunctions implements OnDestroy {
  public openTimelineFromHeader = new BehaviorSubject<boolean>(false);
  public unsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    protected http: HttpClient,
    private storageAdapter: storageAdapter
  ) {}
  public datePipe: DatePipe = new DatePipe('en-US');

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public getBrowserLanguage(): string {
    const browserLang = window.navigator.language.split('-')[0];
    let lang = 'en';
    if (browserLang.match(/en|it|de|pl|ro|ru|sk|hu|bg|cs|hr|mk|sr/)) {
      lang = browserLang;
    }

    return lang;
  }

  public getUserLanguageFromParams(): string {
    let language = 'en';

    this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((queryParams) => {
      if (queryParams !== null && queryParams.lang !== undefined) {
        if (queryParams.lang.match(/en|it|de|pl|ro|ru|sk|hu|bg|cs|hr|mk|sr/)) {
          language = queryParams.lang;
          this.storageAdapter.setLanguage(language);
        } else if (this.storageAdapter.getLanguage()) {
          language = this.storageAdapter.getLanguage().toLocaleLowerCase();
        } else {
          language = this.getBrowserLanguage();
        }
      } else if (this.storageAdapter.getLanguage()) {
        language = this.storageAdapter.getLanguage().toLocaleLowerCase();
      } else {
        language = this.getBrowserLanguage();
      }
    });
    this.translate.use(language);
    return language;
  }
  public getCountry() {
    return this.http.get('./assets/json/country.json');
  }
  public applyCustomScrollbar(): void {
    // do not apply custom scroll bar in iPad, it s having many issues.
    if (window.navigator.platform.indexOf('iPad') >= 0) {
      return;
    }
    // initializes the plugin with "dark" -theme
    OverlayScrollbars(document.getElementsByClassName('custom-scrollbar'), {
      className: 'os-theme-dark',
      // Defines how the overflow should be handled for each axis
      overflowBehavior: {
        // visible-hidden  || visible-scroll || hidden || scroll || v-h || v-s || h || s
        x: 'scroll',
        // visible-hidden  || visible-scroll || hidden || scroll || v-h || v-s || h || s
        y: 'scroll',
      },

      scrollbars: {
        visibility: 'auto',
        autoHide: 'leave',
        dragScrolling: true,
        clickScrolling: false,
        touchSupport: true,
      },
    });
  }
}
