import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

console.log('Read config variables from the Windows object and use the Angular environment as fallback');
const gatewayBaseUrl: string = window['__GATEWAY_BASE_URL__'];
const apiKey: string = window['__API_KEY__'];
@Injectable()
export class getConfigService {
  // When gl-app-carrierRegistration is started in a local env (npm run start), the config variables are note replaced in the index.html file ( eg __API_HOST__ = '<%= apiHost %>';)
  public static gatewayBaseUrl: string = gatewayBaseUrl.startsWith('<%') ? environment.gatewayBaseUrl : gatewayBaseUrl;
  public static apiKey: string = apiKey.startsWith('<%') ? environment.APIKey : apiKey;
  headers: Headers;
  public Base_Api: string;
  constructor() {}

  public getHeaderParams(authToken?: string, contentType: string = 'application/json'): HttpHeaders {
    let Userheaders: HttpHeaders;
    Userheaders = new HttpHeaders({
      'Content-Type': contentType,
      Accept: 'application/json',
    });
    if (authToken) {
      Userheaders = Userheaders.append('X-ZUMO-AUTH', authToken);
    }
    return Userheaders;
  }

  public getFormUrlEncoded(toConvert) {
    const formBody = [];
    for (const property in toConvert) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(toConvert[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }
}
