import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpGruberError } from 'src/app/models/http-gruber-model';
import { ResponseAlert } from 'src/app/models/response-alert';
import { LoaderService } from '../loader.service';
import { AlertService } from './../alert.service';
import { storageAdapter } from './../session.service';

@Injectable()
export class GlobalErrorHandler {
  public alertsArray = {
    400: 'Invalid Data',
    401: 'User is not valid for Request!',
    403: 'User does not have access to this resource',
    404: 'No Data found!',
    409: 'User already exists!',
    500: 'Oops! An Unexpected Error has been occurred',
  };
  constructor(
    protected alertService: AlertService,
    protected router: Router,
    protected StorageAdaptor: storageAdapter,
    private loaderService: LoaderService,
    private translate: TranslateService
  ) {}
  /**
   * This function is used as a generic error handler for our apis
   * @param error: Error
   * @param cb: Call Back Function
   */
  public errorHandler(err: any, excludeErrorList: Number[] = [], cb: Function = (err: any) => {}): void {
    if (!(err instanceof HttpGruberError)) {
      this.alertService.error(null, err?.message);
      return;
    }

    const error = err as HttpGruberError;

    // Condition if Error status should be ignore or not
    if (!excludeErrorList.some((q) => q === error.status)) {
      // We need to check if the token is expired
      if (error.status === 401) {
        this.StorageAdaptor.cleanAll();
        this.router.navigate(['/login']);
      }
      if (error.status === 403) {
        this.router.navigate(['/']);
      }
      this.alertService.error(error, this.getError(error.status));
    }
    if (cb) {
      cb(error);
    }
  }

  /**
   * Return the string error
   * @param status: Pass Error Status
   */
  public getError(status: number): string {
    if (this.alertsArray[status]) {
      return this.alertsArray[status];
    }
    return 'An Unexpected error has been occurred';
  }

  public handleCustomError(alerts: ResponseAlert[], err: HttpGruberError) {
    if (err.status == 401) {
      this.router.navigate(['/login']);
      this.loaderService.display(false);
      return;
    }
    // End - expire token
    const alertType: ResponseAlert[] = this.alertService.filterCustomAlertsById(alerts, err.status);
    const msg: string =
      alertType.length > 0 ? alertType[0].message : err.message ? err.message : this.translate.instant('ErrorMessages.Unexpected Error');
    this.alertService.error(err, msg);
    this.loaderService.display(false);
  }
}
