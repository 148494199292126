/**
 * The error is used by the HttpGruberClient, to wrap an Axios error
 */
export class HttpGruberError extends Error {
  status: number;
  constructor(errMessage: string, code: number = null) {
    super();
    this.message = errMessage;
    this.status = code;
  }
}
