import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/carrier-registration/carrier-registration.module').then((m) => m.CarrierRegistrationModule),
  },
  {
    path: 'carrier-registration-success',
    loadChildren: () =>
      import('./components/carrier-registration-success/carrier-registration-success.module').then((m) => m.CarrierRegistrationSuccessModule),
  },
  {
    path: 'fast',
    loadChildren: () =>
      import('./components/carrier-registration-fast/carrier-registration-fast.module').then((m) => m.CarrierRegistrationFastModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
