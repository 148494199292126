import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { storageAdapter } from './session.service';

/**
 * This class is used as a base class for all our services.
 */
@Injectable()
export class BaseAdapter {
  // This properties contains all the possible response error
  protected alertsArray: object;

  constructor(protected alerts: AlertService, protected router: Router, protected http: HttpClient, protected StorageAdaptor: storageAdapter) {
    this.alertsArray = {
      400: 'Invalid Data',
      401: 'User is not valid for Request!',
      403: 'User does not have access to this resource',
      404: 'No Data found!',
      409: 'User already exists!',
      500: 'Oops! An Unexpected Error has been occured',
    };
  }

  /**
   * This function is used to return the string the proper url that we need to call
   * @param endpoint  : Name of End Point.
   * @param params : Parameters that you want to pass as a query string or just append to url.
   * @param isQueryParam: Decide whether the params passed are query params or not
   */
  protected buildQuery(baseUrl: string, endpoint: string, params: any, isQueryParam = true): string {
    const parameters = [];
    let currentEndpoint: string = baseUrl + endpoint;
    if (isQueryParam) {
      for (const param in params) {
        parameters.push(param + '=' + params[param]);
      }

      if (parameters.length > 0) {
        currentEndpoint += '?' + parameters.join('&');
      }
    } else {
      currentEndpoint += '/' + params.map((value) => encodeURIComponent(value)).join('/');
    }

    return currentEndpoint;
  }

  /**
   * Return the string error
   * @param status: Pass Error Status
   */
  private getError(status: number): string {
    if (this.alertsArray[status]) {
      return this.alertsArray[status];
    }
    return 'Oops! An Unexpected Error has been occured';
  }

  /**
   * This function is used as a generic error handler for our apis
   * @param error: Error
   * @param cb: Call Back Function
   */
  public errorHadler(error: any, excludeErrorList: Number[], cb: Function = (err: any) => {}): void {
    // Condition if Error status should be ignore or not
    if (!excludeErrorList.some((q) => q === error.status)) {
      // We need to check if the token is expired
      if (error.status === 401) {
        this.StorageAdaptor.cleanAll();
        this.router.navigate(['/login']);
      }
      if (error.status === 403) {
        this.router.navigate(['/']);
      }
      this.alerts.error(error, this.getError(error.status));
    }
    if (cb) {
      cb(error);
    }
  }

  /**
   * This is a simple wrapper used for the POST requests
   * @param base :Base URL
   * @param endpoint : End Point Name
   * @param parameters : Parameters as a query string
   * @param body : Body Message that you want to pass
   * @param headers : headers that you want to pass
   * @param dataHandler : Data Function that is called after the sucessfully call of EndPoint
   * @param errorHadler : Error Function that is called after the error call of EndPoint
   */
  protected post<T>(
    base: string,
    endpoint: string,
    parameters: object,
    body: any,
    headers: any,
    dataHandler: Function = (data: any) => {},
    errorHadler: Function = (error: any) => {},
    excludeErrorList: Number[] = []
  ): any {
    const url = this.buildQuery(base, endpoint, parameters);
    body = body ? body : '';
    this.http.post<T>(url, body, headers).subscribe(
      (data) => {
        dataHandler(data);
      },
      (error) => {
        this.errorHadler(error, excludeErrorList, errorHadler);
      }
    );
  }

  /**
   * This is a simple wrapper used for the GET requests
   * @param base :Base URL
   * @param endpoint : End Point Name
   * @param parameters : Parameters as a query string
   * @param headers : headers that you want to pass
   * @param dataHandler : Data Function that is called after the sucessfully call of EndPoint
   * @param errorHadler : Error Function that is called after the error call of EndPoint
   */
  protected get<T>(
    base: string,
    endpoint: string,
    parameters: object,
    headers: any,
    dataHandler: Function = (data: any) => {},
    errorHadler: Function = (error: any) => {},
    excludeErrorList: Number[] = []
  ): any {
    const url = this.buildQuery(base, endpoint, parameters);
    this.http.get<T>(url, headers).subscribe(
      (data) => {
        dataHandler(data);
      },
      (error) => {
        this.errorHadler(error, excludeErrorList, errorHadler);
      }
    );
  }

  /**
   * This is a simple wrapper used for the DELETE requests
   * @param base :Base URL
   * @param endpoint : End Point Name
   * @param parameters : Parameters as a query string
   * @param headers : headers that you want to pass
   * @param dataHandler : Data Function that is called after the sucessfully call of EndPoint
   * @param errorHadler : Error Function that is called after the error call of EndPoint
   */
  protected delete(
    base: string,
    endpoint: string,
    parameters: object,
    headers: any,
    dataHandler: Function = (data: any) => {},
    errorHadler: Function = (error: any) => {},
    excludeErrorList: Number[] = []
  ): any {
    const url = this.buildQuery(base, endpoint, parameters);
    this.http.delete(url, headers).subscribe(
      (data) => {
        dataHandler(data);
      },
      (error) => {
        this.errorHadler(error, excludeErrorList, errorHadler);
      }
    );
  }
}
